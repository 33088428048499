<template>

    <main ref="resumen" id="resumen">
        <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
        <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
        <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
        <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
        <div class="py-2"><ordenClinicaMedicamentoShow :idHistoria="idHistoria"></ordenClinicaMedicamentoShow></div>
        <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
    </main>

</template>
<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import ordenClinicaMedicamentoShow from "../../base/orden/ordenClinicaMedicamentoShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";

export default {
    props: ['idHistoria','idUsuario'],
    components:{motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    impresionDxShow,
    exploracionFisicaShow,ordenClinicaMedicamentoShow},
    
}
</script>
<style scoped>
.resumen-container {
    height:300px;
    overflow-y:scroll
}

</style>