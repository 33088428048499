<template>
    <main>
        <div class=" ">
            <div class="card-header bg-light "><span><i class="fa fa-first-aid"> </i> Ordenes Medicamentos</span> </div>
            <div class="card-body">
                <div class="alert alert-warning" v-if="!ordenes.length">
                    No se han registrado ordenes medicas...
                </div>
                <div class="table-responsive">
                    <div v-for="or in ordenes" :key="or.id" class="mb-3">
                        <span class="text-info">Orden CLinica No. {{or.id}}</span>
                        <table
                            class="table table-borderless mb-0 table-sm"
                        >
                            <thead class="border-bottom">
                            <tr class="small text-uppercase text-muted">
                                <th scope="col">Examen/Procedimiento</th>
                                <th scope="col">Cantidad</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr
                                    class="border-bottom"
                                    v-for="item in or.items" :key="item.id"
                                >
                                    <td>
                                        <div class="font-weight-bold">
                                            {{ item.servicio.codigo }}
                                            {{ item.servicio.descripcion }}
                                            <div class="small text-muted d-none d-md-block">
                                                Observación: {{ item.observacion }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ item.cantidad }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import ordenClinicaService from "../../../../services/ordenClinicaService";
export default {
    props: ['idHistoria'],
    data(){
        return{
            ordenes: [],
        }
    },
    methods: {
        async cargarOrdenes(){
            const response =await ordenClinicaService.showOrdenByHcAndType({
                id_historia:this.idHistoria,
                type:'medicamento'
            });
            this.ordenes= response.data;
        }
    },
    created() {
        this.cargarOrdenes();
    }
}
</script>

