<template>
  <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact>Historia Clinica - Urgencias</page-header-compact>
    <br />
    <div class="container-xl px-4">
      <div class="card ">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <br />
      <wizard
        steps="10"
        @prev="prev($event)"
        @next="next($event)"
        :current_step="current"
      >
        <template #tabs>
          <wizard-tab
            title="Motivo Consulta"
            step="1"
            active="true"
            id="step1"
            v-on:tab-click="next({ prev: current, current: 1 })"
          ></wizard-tab>
          <wizard-tab
            title="Revisión"
            step="2"
            id="step2"
            v-on:tab-click="next({ prev: current, current: 2 })"
          ></wizard-tab>
          <wizard-tab
            title="Examen Fisico"
            step="3"
            id="step3"
            v-on:tab-click="next({ prev: current, current: 3 })"
          ></wizard-tab>
          <wizard-tab
            title="Impresión Diagnostica"
            step="4"
            id="step4"
            v-on:tab-click="next({ prev: current, current: 4 })"
          ></wizard-tab>
          <wizard-tab
            title="Analisis"
            step="5"
            id="step5"
            v-on:tab-click="next({ prev: current, current: 5 })"
          ></wizard-tab>
          <wizard-tab
            title="Conducta"
            step="6"
            id="step6"
            v-on:tab-click="next({ prev: current, current: 6 })"
          ></wizard-tab>
          <wizard-tab
            title="Procedimientos"
            step="7"
            id="step7"
            v-on:tab-click="next({ prev: current, current: 7 })"
          ></wizard-tab>
          <wizard-tab
            title="Medicamentos"
            step="8"
            id="step8"
            v-on:tab-click="next({ prev: current, current: 8 })"
          ></wizard-tab>
          
          <wizard-tab
              title="Resumen"
              step="9"
              id="step9"
              v-on:tab-click="next({ prev: current, current: 9 })"
          ></wizard-tab>
          
        </template>
        <template #tab-content>
          <wizard-tab-pane id="step1" active="true">
            <template #content>
              <component
                :is="currentComponent"
                :ref="currentComponent"
                v-bind="currentProperties"
              ></component>
            </template>
          </wizard-tab-pane>
        </template>
        <template #finish>
          <cerrar-historia :id-usuario="usuario.id" :id-historia="idHistoria" :usuario="usuario"></cerrar-historia>
        </template>
      </wizard>
    </div>
  </main>
</template>
<script>

import Wizard from "../../../components/common/utilities/Wizard/Wizard";
import WizardTab from "../../../components/common/utilities/Wizard/WizardTab";
import WizardTabPane from "../../../components/common/utilities/Wizard/WizardTabPane";
import motivoConsultaComponent from "../base/motivoConsulta/motivoConsulta";
import examenFisicoComponent from "../base/examenFisico/examenFisico";
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import historiaClinicaService from "../../../services/historiaClinicaService";
import impresionDXComponent from "../base/impresionDX/impresionDXComponent";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import analisisComponent from "../base/baseUrgencia/analisis";
import conductaComponent from "../base/baseUrgencia/conducta";
import revisionComponent from "../base/baseUrgencia/revision";
import ordenComponent from "../base/orden/ordenComponent";
import ordenMedicamentoComponent from "../base/orden/ordenMedicamentoComponent";
//import formulaMedicaComponent from "../base/formulaMedica/formulaMedicaComponent";
import cerrarHistoria from "@/views/historias/base/cerrarHistoria/cerrarHistoria";
import resumenHcComponent from "./resumenHc/resumenHcComponent";

export default {
  components: {
    cerrarHistoria,
    PageHeaderCompact,
    motivoConsultaComponent,
    examenFisicoComponent,
    WizardTabPane,
    WizardTab,
    Wizard,
    impresionDXComponent,
    modalInformacionUsuario,
    cardInformacionUsuario,
    analisisComponent,
    conductaComponent,
    revisionComponent,
    ordenComponent,
    ordenMedicamentoComponent,resumenHcComponent
  },
  data() {
    return {
      finalizada: "",
      idHistoria: "",
      usuario: {},
      current: 1,
      checkpoint : 1,
      steps: [
        { stepId: "#step1", ref: "motivoConsultaComponent", validate: true },
        { stepId: "#step2", ref: "revisionComponent", validate: true },
        { stepId: "#step3", ref: "examenFisicoComponent", validate: true },
        { stepId: "#step4", ref: "impresionDXComponent", validate: true },
        { stepId: "#step5", ref: "analisisComponent", validate: true },
        { stepId: "#step6", ref: "conductaComponent", validate: true },
        { stepId: "#step7", ref: "ordenComponent", validate: false },
        { stepId: "#step8", ref: "ordenMedicamentoComponent", validate: false },
        { stepId: "#step9", ref: "resumenHcComponent", validate: false },

      ],
    };
  },
  methods: {
    prev(step) {

      this.current = step.current;
      this.activeTab();
      
    },
    next(step) {
      
      if(step.prev <= this.checkpoint && step.current-1 > step.prev){
        this.activeTab();
        console.log(step.prev);
        return;
      }
      
      const refComponent = this.steps[step.prev - 1].ref;
      
      if (typeof this.$refs[refComponent].save !== "undefined") {
        this.$refs[refComponent].save();
      }

      if (this.steps[step.prev - 1].validate) {
        if (this.$refs[refComponent].$v.$invalid) return;
      }

      if(step.current > this.checkpoint) {
        historiaClinicaService.updateCheckpoint(this.idHistoria, step.current);
        this.checkpoint = step.current;
      }

      this.current = step.current;

      this.activeTab();
    },
    async cargar(id) {
      
      const response = await historiaClinicaService.show(id);
      
      this.usuario = response.data.usuario;
      this.finalizada = response.data.finalizada;
      this.current = response.data.checkpoint;
      this.checkpoint = response.data.checkpoint;

      this.activeTab()

    },
    activeTab(){
      document.querySelectorAll(`a.active`).forEach(a =>{ a.classList.remove('active') });
      document.querySelector(`a[href='${this.steps[this.current - 1].stepId}']`).classList.add("active");
    }
  },
  created() {
    this.idHistoria = this.$route.params.id;
    this.cargar(this.idHistoria);
  },
  computed: {
    currentProperties() {
      if (this.currentComponent === "motivoConsultaComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }
      if (this.currentComponent === "analisisComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }
       if (this.currentComponent === "conductaComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }
       if (this.currentComponent === "revisionComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }
      if (this.currentComponent === "examenFisicoComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          modo : 'URGENCIA'
        }
      }
      if (this.currentComponent === "ordenComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario : this.usuario,
        }
      }
      if (this.currentComponent === "ordenMedicamentoComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario : this.usuario,
        }
      }
      if (this.currentComponent === "impresionDXComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }
      if (this.currentComponent === "resumenHcComponent") {
        return {
          idHistoria: this.idHistoria,
          idUsuario:this.usuario.id,
        }
      }
      

      return {}
    },
    currentComponent() {
      let components = [
        "motivoConsultaComponent",
        "revisionComponent",
        "examenFisicoComponent",
        "impresionDXComponent",
        "analisisComponent",
        "conductaComponent",
        "ordenComponent",
        "ordenMedicamentoComponent",
        "resumenHcComponent",
      ];

      return components[this.current - 1];
    },
  },
}
</script>

<style scoped></style>
